var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as $ from 'jquery';
import { db } from './database';
export { getMealId, getRecipe, getRecipeById };
function getMealId(el) {
    return __awaiter(this, void 0, void 0, function* () {
        const target = $(el).hasClass('recipe') ? $(el) : $(el).parents('.recipe');
        return yield target.data('meal-id');
    });
}
function getRecipeById(recipeId) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield db.recipes.get(recipeId, (recipe) => __awaiter(this, void 0, void 0, function* () {
            if (recipe)
                return recipe;
            return yield $.ajax({ url: `/api/recipes/${recipeId}/view` }).then(hits => {
                if (hits.total === 1)
                    return hits.results[0];
            });
        }));
    });
}
function getRecipe(el) {
    return __awaiter(this, void 0, void 0, function* () {
        let recipe = null;
        const target = $(el).hasClass('recipe') ? $(el) : $(el).parents('.recipe');
        const recipeList = $(target).parents('table[data-row-attributes]');
        if (recipeList.length) {
            const index = target.data('index');
            recipe = $(recipeList).bootstrapTable('getData')[index];
        }
        else {
            const recipeId = target.data('id');
            recipe = yield getRecipeById(recipeId);
        }
        return recipe;
    });
}
