var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as $ from 'jquery';
import { db } from '../database';
import { addProduct } from '../models/products';
export { addRecipe, removeRecipe, scaleRecipe };
function addRecipe(recipe) {
    return __awaiter(this, void 0, void 0, function* () {
        return db.transaction('rw', db.recipes, db.products, db.ingredients, () => {
            void db.recipes.add(recipe).then(() => {
                $.each(recipe.ingredients, (index, ingredient) => {
                    addProduct(ingredient, recipe.id, index);
                });
            });
        }).then(() => recipe.id);
    });
}
function removeRecipe(recipe) {
    return __awaiter(this, void 0, void 0, function* () {
        return db.transaction('rw', db.recipes, db.meals, db.ingredients, () => {
            void db.recipes
                .delete(recipe.id);
            void db.meals
                .where("recipe_id")
                .equals(recipe.id)
                .delete();
            void db.ingredients
                .where("recipe_id")
                .equals(recipe.id)
                .delete();
        }).then(() => recipe.id);
    });
}
function scaleRecipe(recipe, targetServings) {
    $.each(recipe.ingredients, function () {
        this.quantity.magnitude *= targetServings;
        this.quantity.magnitude /= recipe.servings;
    });
    recipe.servings = targetServings;
}
